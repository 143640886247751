import React from "react"

import Layout from "../components/layout"
import ContactUs from "../components/ContactUs"
import PageHeader from "../components/PageHeader"
import { CustomerImage } from "../components/background/BackgroundCustomer"

const SecondPage = () => (
  <Layout>
    <PageHeader title="Careers" displayTitle="Our Customers" subtitle="" />
    <section className="mt-5">
      <div className="container background-plus">
        <div className="row">
          <div className="col-lg-6 text-center">
            <div className="img-wrap box-bottom-right height-500 width-100">
              <CustomerImage />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Our Customer Relationships</h2>
            </div>
            <p>VoerEir collaborates with a diverse range of customers, catering to both Telco and IT sectors, with Telco being our primary focus.</p>
            <p>Among our esteemed clientele, we are proud to count a Tier-1 Telco with operations spanning across multiple European countries as 
              our key customer. They rely on our flagship product, Touchstone, for cloud testing and validation purposes, seamlessly integrating
               it into their Telco Cloud's continuous deployment and integration (CD/CI) process. This strong and mutually beneficial relationship
              has flourished over several years, with Touchstone deployed in over 20 Data Centers across 10 different countries.</p>
            <p>In addition, we offer Test-as-a-Service (TaaS) to multiple Telco Tier-1 customers in Europe. Through this model, 
              we conduct comprehensive audits of their Telco Cloud infrastructure, ensuring its verification and optimization 
              to achieve optimal performance.</p>
            <p>Expanding our reach to the IT sector, we proudly serve a prominent Swedish public IT Cloud provider as a major customer. 
              In this capacity, we provide invaluable consulting services to assist them in their endeavors.</p>
            <p>

            </p>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default SecondPage

